import http, { upload } from '@/http'
import API from '@/http/api'

http.defaults.baseURL = process.env.VUE_APP_OPEN_API

export const getCompanyList = (data: any) => {
  return http({
    url: API.companyList,
    method: 'get',
    params: data
  })
}

export const getElectronicInvoiceInfo = (data: any) => {
  return http({
    url: API.queryElectronicInvoiceInfo,
    method: 'post',
    params: data
  })
}

export const uploadPhoto = (file: File) => {
  const data: Record<string, any> = {
    files: file,
    describe: 'other',
    type: 2,
    businessType: 9
  }
  return upload(API.appletsUpload, data)
}

export const getRegionList = () => {
  return http({
    url: API.regionList,
    method: 'get'
  })
}

export const addInvoice = (data: Record<string, any>) => {
  return http({
    url: API.addInvoice,
    method: 'post',
    data
  })
}

// 校验发票信息
export const checkInvoice = (data: Record<string, any>) => {
  return http({
    url: API.checkInvoice,
    method: 'post',
    data
  })
}

// 手机号获取开票申请记录
export const obtainInvoiceApplication = (data: Record<string, any>) => {
  return http({
    url: API.obtainInvoiceApplication,
    method: 'get',
    params: data
  })
}

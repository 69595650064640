
import { defineComponent } from 'vue'
import { ImagePreview } from 'vant'

export default defineComponent({
  name: 'LinkCourse',
  setup() {
    const baseURL = process.env.VUE_APP_STATIC_OSS
    const linkImg = `${baseURL}/image/wechat/link-demo.jpg?x-oss-process=image/resize,l_500`
    const linkVideo = `${baseURL}/image/wechat/link-demo-video2.mp4`

    function preview() {
      ImagePreview([linkImg])
    }

    return { linkImg, preview, linkVideo }
  }
})

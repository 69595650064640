import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/link-video-poster.png'


const _withScopeId = n => (_pushScopeId("data-v-3d7629f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  style: {"width":"100%"},
  height: "440",
  poster: _imports_0,
  controls: "",
  type: "video/mp4"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_loading = _resolveComponent("van-loading")!
  const _component_van_image = _resolveComponent("van-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", _hoisted_2, [
      _createElementVNode("source", {
        src: _ctx.linkVideo,
        type: "video/mp4"
      }, null, 8, _hoisted_3),
      _createTextVNode(" 您的浏览器不支持Video标签。 ")
    ]),
    _createVNode(_component_van_image, {
      style: {"margin-top":"20px"},
      width: "100%",
      fit: "contain",
      src: _ctx.linkImg,
      onClick: _ctx.preview
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_van_loading, {
          type: "spinner",
          size: "20"
        })
      ]),
      _: 1
    }, 8, ["src", "onClick"])
  ]))
}
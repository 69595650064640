export const invoiceTypeEnum = [
  // {
  //   text: '纸质发票（专票）',
  //   value: 0
  // },
  {
    text: '电子发票（普票）',
    value: 1
  },
  {
    text: '电子发票（专票）',
    value: 2
  }
]

export const typeExpenseEnum = [
  {
    text: '安装费',
    value: 1
  },
  {
    text: '维修费',
    value: 2
  },
  {
    text: '服务续费',
    value: 3
  },
  {
    text: '对接费',
    value: 4
  }
]

// 	开票状态
export const invoiceStateEnum = [
  {
    text: '待开票',
    value: 0,
    colorClass: ''
  },
  {
    text: '已开票',
    value: 1,
    colorClass: 'green-text'
  },
  {
    text: '已取消',
    value: 2,
    colorClass: 'red-text'
  },
  {
    text: '开票中',
    value: 3,
    colorClass: ''
  }
]
